import { mixins } from "@/plugins/mixins";
export default {
  name: "monitor-battery",
  mixins: [mixins],
  components: {},
  data() {
    return {
      logDialog: false,
      logData: [],
      // 日志data
      dataTotal: 0,
      currentPage: 1,
      pageSize: 10,
      pageSizeOptions: [10, 20, 30, 40, 50, 100],
      columns: [{
        label: '电池编号',
        prop: 'batteryCode'
      }, {
        label: '电池组制造厂名称',
        prop: 'bmsManufacturer'
      }, {
        label: '型号',
        prop: 'batteryModeName'
      }, {
        label: '电量',
        prop: 'remainingCapacity'
      }, {
        label: '所在位置',
        prop: 'positionType'
      }, {
        label: '具体位置',
        prop: 'position'
      }, {
        label: '设备状态',
        prop: 'deviceStatusDesc'
      }, {
        label: '启用状态',
        prop: 'statusDesc'
      }],
      topButtons: [],
      linkButtons: [{
        name: '日志',
        code: 'monitorBatteryLog',
        click: this.getLog,
        permissions: ['monitorBatteryLog']
      }],
      searchItems: [{
        prop: 'batteryModeId',
        label: '电池型号',
        type: 'select',
        items: [],
        itemProp: {
          label: 'name',
          value: 'id'
        },
        remote: true,
        loading: false,
        remoteMethod: name => {
          if (name.length >= 1) {
            this.searchItems[0].loading = true;
            this.$api.business.batteryModel.getList({
              page: 1,
              size: 20,
              name: name
            }).then(res => {
              if (res.code === 200) {
                this.searchItems[0].items = res.data.records;
              }
            }).finally(() => {
              this.searchItems[0].loading = false;
            });
          }
        }
      }, {
        prop: 'batteryCode',
        label: '电池编号'
      }, {
        prop: 'positionType',
        label: '所在位置',
        type: 'select',
        itemProp: {
          label: 'label',
          value: 'value'
        },
        items: [{
          label: '充电站',
          value: 'chargingStation'
        }, {
          label: '车辆',
          value: 'vehicle'
        }, {
          label: '仓库',
          value: 'warehouse'
        }, {
          label: '运维人员',
          value: 'user'
        }]
      }, {
        prop: 'deviceStatus',
        label: '设备状态',
        type: 'select',
        items: ['正常', '检修', '报废']
      }]
    };
  },
  methods: {
    getLog(row) {
      this.$api.business.deviceOperationLog.getList({
        page: this.currentPage,
        size: this.pageSize,
        deviceId: row.id
      }).then(res => {
        this.logData = res.data.records;
        this.dataTotal = res.data.total;
        this.logDialog = true;
      });
    }
  }
};